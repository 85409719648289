import templates from "../config/templates"
import { useCallback } from "react"
import { useRouter } from "next/router"
import { usePreviewSubscription } from "./sanity"

function usePage({ data, preview }) {
    const router = useRouter()
    const onChange = useCallback(() => {
        router.replace(router.asPath, router.asPath, { scroll: false })
    })
    const subscription = usePreviewSubscription(onChange, {
        enabled: preview,
        query: data.draftQuery,
        params: data.queryParams,
        debounce: 650,
    })

    const TemplateComponent = templates[data.page._type] || templates["page"]

    return {
        TemplateComponent,
        page: data.page,
        preview,
    }
}

export default usePage
