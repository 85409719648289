import PageTemplate from "../components/templates/PageTemplate"
import GuideTemplate from "../components/templates/GuideTemplate"
import ThemeTemplate from "../components/templates/ThemeTemplate"
import ArticleTemplate from "../components/templates/ArticleTemplate"
import InitiativeTemplate from "../components/templates/InitiativeTemplate"

export default {
    page: PageTemplate,
    guide: GuideTemplate,
    theme: ThemeTemplate,
    article: ArticleTemplate,
    initiative: InitiativeTemplate,
}
