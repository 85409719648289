import { useEffect, useState } from "react"
import StandardArticleHero from "./StandardArticleHero"
import FeatureArticleHero from "./FeatureArticleHero"
import PodcastArticleHero from "./PodcastArticleHero"
import OpinionArticleHero from "./OpinionArticleHero"
import PressArticleHero from "./PressArticleHero"
import { urlFor } from "../lib/sanity"

export default function ArticleHero({ article }) {
    const { type, image } = article
    const [imageUrl, setImageUrl] = useState(null)

    useEffect(() => {
        if (typeof window !== undefined && image?.asset?._ref) {
            setImageUrl(urlFor(image).width(window.innerWidth).url())
        }
    }, [])

    if (type == "podcast") return <PodcastArticleHero {...article} />
    if (type == "feature") return <FeatureArticleHero {...article} />
    if (type == "opinion") return <OpinionArticleHero {...article} />
    if (type == "pressrelease") return <PressArticleHero {...article} />

    return <StandardArticleHero {...article} />
}
